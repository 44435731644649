import React, { useEffect, useState } from 'react'
import RegistrationFormSection from './RegistrationFormSection'
import CycleLangSelection from './CycleLangSelection'
import useApiUrlTranslation from './useApiUrlTranslation';

function RegistrationFormProgrammesActionSection({ formulaire }) {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [eventContactTypeList, setEventContactTypeList] = useState();

    const getEventContactTypeList = async () => {

        await fetch(`${apiUrlTranslation}?action=getEventContactTypeList&filter=WHERE%20event_contact_type_is_active%20=1`)

            .then(response => response.json())
            .then(data => {
                setEventContactTypeList(data);
            })
    }

    useEffect(() => {
        getEventContactTypeList();
    }, [i18n.resolvedLanguage])

    if (!eventContactTypeList) return null;

    return (
        <RegistrationFormSection

            title={formulaire.programmes_action_title}
            instructions={formulaire.programmes_action_instructions}
            formSectionId={36}>


            <CycleLangSelection
                formulaire={formulaire}
                idCycleLangList={[3240, 3242, 3241, 3239]}
                cycleLangSelection={
                    [
                        { id_cycle_lang: 3240, eventContactType: eventContactTypeList.find(eventContactType => eventContactType.id_event_contact_type == 237) },
                        { id_cycle_lang: 3242, eventContactType: eventContactTypeList.find(eventContactType => eventContactType.id_event_contact_type == 2) },
                        { id_cycle_lang: 3241, eventContactType: eventContactTypeList.find(eventContactType => eventContactType.id_event_contact_type == 307) },
                        { id_cycle_lang: 3239, eventContactType: eventContactTypeList.find(eventContactType => eventContactType.id_event_contact_type == 308) }
                    ]
                }
            />


        </RegistrationFormSection>
    )
}

export default RegistrationFormProgrammesActionSection