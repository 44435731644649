import React, { useState, useEffect } from 'react'
import RegistrationFormSection from './RegistrationFormSection'
import useApiUrlTranslation from './useApiUrlTranslation';
import { API_URL } from '../config';
import axios from 'axios';
import { set } from 'react-hook-form';
import HTMLReactParser from 'html-react-parser';

const CycleLangMainPreview = ({ cycleLangMain, cycleLangMainPreviewIsVisible }) => {


    useEffect(() => {

    }, [cycleLangMain]);


    if (!cycleLangMain) return null;

    return (

        <div className={`py-3 space-x-5 ${cycleLangMainPreviewIsVisible ? 'flex' : 'hidden'}`}>
            <div className="basis-1/6 flex justify-center ">
                <div className='bg-neutral-200 p-3 flex justify-center items-center rounded '>
                    <img src={cycleLangMain.logo_better.small}
                        className=" object-contain " alt="" />
                </div>
            </div>
            <div className="basis-5/6">

                <p className="font-bold text-5xl mb-3">{cycleLangMain.nom_cycle_lang}</p>
                {HTMLReactParser(cycleLangMain.cycle_lang_descriptif ?? "")}</div>
        </div>
    )

}

const CycleLangMainSelection = ({ idCycleLangList, formulaire,
    cycleLangMainPreviewIsVisible,
    cycleLangMain, setCycleLangMain }) => {

    const [cycleLangPreselectionList, setCycleLangPreselectionList] = useState(false);

    const [programmeActionCollectiveList, setProgrammeActionCollectiveList] = useState(false);

    const [logoPreview, setLogoPreview] = useState(null);

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileInput = (e) => {

        e.preventDefault();

        // console.log(e.target.files[0]);
        setSelectedFile(e.target.files[0]);

        setLogoPreview(URL.createObjectURL(e.target.files[0]));

        handleSubmitFile(e);
    }

    const handleSubmitFile = event => {
        event.preventDefault()
        const url = 'https://www.mlg-consulting.com/manager_cc/cycles/do.php';

        const formData = new FormData();
        formData.append('Filedata', selectedFile);
        formData.append('fileName', selectedFile.name);
        formData.append("attached_file_table", "cycle_lang");
        formData.append("primary_key_name", "id_cycle_lang");
        formData.append("do", "attached_file");
        formData.append("primary_key_value", cycleLangMain.id_cycle_lang);
        formData.append("attached_file_libelle", 'Logo Better');
        formData.append("id_attached_file_type", 65);

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        axios.post(url, formData, config).then((response) => {
            console.log(response.data);
        })
            .then(res => fetchCycleLangPreselection());

    }


    const handleChangeParent = async (e) => {

        const id_cycle_lang = e.target.value;

        await fetch(`${API_URL}?action=updateCycleLangParent&id_parent=${id_cycle_lang}&idcl=${cycleLangMain.id_cycle_lang}`)
            .then(res => fetchCycleLangPreselection())
    }

    const handleChange = async (e) => {

        const cycleLang = { ...cycleLangMain, [e.target.name]: e.target.value }

        setCycleLangMain(cycleLang);

        await fetch(`${API_URL}?action=updateCycleLang`, {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                cycleLang: {
                    id_cycle_lang: cycleLang.id_cycle_lang,
                    cycle_lang_descriptif: cycleLang.cycle_lang_descriptif,
                    cycle_lang_categorie: cycleLang.cycle_lang_categorie
                }
            })
        })
            .then(res =>
                fetchCycleLangPreselection()
            )
    }

    const fetchCycleLangPreselection = async () => {

        const currentURL = window.location.href;
        const searchParams = new URLSearchParams(currentURL.split('?')[1]);
        const idCycleLangPreselectionList = searchParams.get('id_cycle_lang_preselection_list');

        const filter = ` id_cycle_lang IN(${idCycleLangPreselectionList}) AND `

        await fetch(`${apiUrlTranslation}?action=getCycleLang&filter=${filter}`)
            .then(res => res.json())

            .then(res => {
                setCycleLangPreselectionList(res);
                setCycleLangMain(res.find(cycleLang => cycleLang.cycle_lang_type == "collectif_partenaire"))
            })
    }

    const fetchCycleLang = async () => {

        const filter = ` id_cycle_lang IN(3240, 3242, 3241, 3239) AND `

        await fetch(`${apiUrlTranslation}?action=getCycleLang&filter=${filter}`)
            .then(res => res.json())

            .then(res => {
                setProgrammeActionCollectiveList(res);
            })
    }


    useEffect(() => {

        fetchCycleLangPreselection();

        fetchCycleLang();

    }, [i18n.resolvedLanguage]);

    if (!cycleLangMain) return null


    return (
        <div className={` ${cycleLangMainPreviewIsVisible ? 'hidden' : ''}`}>


            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-4">
                <label htmlFor="last-name" className="block text-sm  leading-6 text-gray-900 sm:pt-1.5 font-bold">
                    Logo
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <img src={logoPreview ?? cycleLangMain.logo_better.small} className="mb-3 w-40 ring-4" />
                    <input type="file" name="" id="" onChange={handleFileInput} />
                </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-4">
                <label htmlFor="last-name" className="block text-sm font-bold leading-6 text-gray-900 sm:pt-1.5">
                    Description
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <textarea
                        onChange={handleChange}
                        rows={5}
                        className="block w-full  "
                        name="cycle_lang_descriptif" value={cycleLangMain.cycle_lang_descriptif}></textarea>
                </div>
            </div>


            {HTMLReactParser(t('form.sections.client_partenaire_section.modalite_colaboration_title'))}


            <label className="block font-normal" >
                <input
                    checked={cycleLangMain.cycle_lang_categorie == "collaboration_marchande"}
                    type="radio"
                    name="cycle_lang_categorie"
                    value="collaboration_marchande"
                    onClick={handleChange} />  {HTMLReactParser(t('form.sections.client_partenaire_section.collaboration_marchande'))}
            </label>
            <label className="block font-normal" >
                <input
                    checked={cycleLangMain.cycle_lang_categorie == "collaboration_non_marchande"}
                    type="radio"
                    name="cycle_lang_categorie"
                    value="collaboration_non_marchande"
                    onClick={handleChange} />  {HTMLReactParser(t('form.sections.client_partenaire_section.collaboration_non_marchande'))}
            </label>


            {HTMLReactParser(t('form.sections.client_partenaire_section.programme_action_title'))}

            {programmeActionCollectiveList && programmeActionCollectiveList.map(programmeActionCollective =>
                <label className="block font-normal" >
                    <input
                        checked={cycleLangMain.parents && cycleLangMain.parents.map(parent => parent.id_cycle_lang).includes(programmeActionCollective.id_cycle_lang)}
                        type="checkbox"
                        value={programmeActionCollective.id_cycle_lang}
                        onChange={handleChangeParent} /> {programmeActionCollective.nom_cycle_lang}
                </label>)}
        </div>
    )

}

function RegistrationFormClienPartenaireSection({ formulaire }) {

    const { t, i18n } = useApiUrlTranslation();

    const [cycleLangMain, setCycleLangMain] = useState(false);

    const [cycleLangMainPreviewIsVisible, setCycleLangMainPreviewIsVisible] = useState(true);

    const handleEditButton = (e) => {
        e.preventDefault();

        setCycleLangMainPreviewIsVisible(!cycleLangMainPreviewIsVisible);
    }

    useEffect(() => {

    }, [cycleLangMain, i18n.resolvedLanguage]);

    return (
        <RegistrationFormSection
            formSectionId={34}
            title={formulaire.client_partenaire_section_title}
            instructions={formulaire.client_partenaire_section_instructions}>

            <div className='flex justify-end'>
                <button
                    onClick={handleEditButton}
                    className=" border-2 bg-blue-200 rounded-full w-28 text-black border-blue-600 ">
                    {!cycleLangMainPreviewIsVisible ? t('form.voir_appercu') : t('form.modifier')}
                </button>
            </div>

            <CycleLangMainPreview
                cycleLangMainPreviewIsVisible={cycleLangMainPreviewIsVisible}
                cycleLangMain={cycleLangMain} />

            <CycleLangMainSelection
                cycleLangMainPreviewIsVisible={cycleLangMainPreviewIsVisible}
                cycleLangMain={cycleLangMain}
                setCycleLangMain={setCycleLangMain}
            />

        </RegistrationFormSection>
    )
}

export default RegistrationFormClienPartenaireSection