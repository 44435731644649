import { useEffect, useState, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import RegistrationFormSection from './RegistrationFormSection';
import EventContext from '../contexts/EventContext'
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import useApiUrlTranslation from './useApiUrlTranslation';
import RegistrationFormContactCycleLangList from './RegistrationFormContactCycleLangList';

const statutList_1 = [
    { label: "Je ne prévois pas de m'y rendre, mais je souhaite que des contacts de mon collectif présent sur place puissent participer aux initiatives de MGV", id: 245 },
    { label: "Je ne prévois pas de m’y rendre mais souhaite connecter à distance les membres de mon collectif dans le cadre des initiatives de myGlobalVillage (tech’review, pitch, débriefing…)", id: 246 },
    { label: "Je ne prévois pas de m’y rendre mais souhaite connecter à distance un lieu ou un événement de mon écosystème pour y réaliser un événement « phygital »", id: 209 }
]

const statutList_2 = [
    { label: "Je prévois de m'y rendre seul et souhaite participer aux initiatives de MGV.", id: 143 },
    { label: "Je prévois de m'y rendre dans le cadre d'une délégation dont je ne suis pas l'organisateur. Mais souhaite participer aux initiatives de mGV ", id: 244 },
    { label: "Je prévois de m'y rendre avec une délégation dont je suis l'organisateur et qui pourrait participer aux initiatives de mGV", id: 241 }
]

const statutList_3 = [
    { label: "J'ai un stand et souhaiterai accueillir des tech’xplorateurs et délégations MGV.", id: 242 },
    { label: "J'ai un stand avec une salle ou scène pouvant héberger des temps forts MGV ", id: 243 }
]

const statutList_4 = [
    { label: "Je suis l'organisateur de l'événement et dispose d'une infrastructure ou de salles permettant d'accueillir les temps forts de MGV.", id: 226 }
]


const DisplayStatutList = ({ title, statutList, eventContactTypeList }) => {

    return <div>


        <div className='bg-blue-200 p-2 rounded my-3'>{title}</div>

        {eventContactTypeList && eventContactTypeList.map((eventContactType, index) =>
            <EventContactType eventContactType={eventContactType} key={`ect-${index}`} />
        )}

        {
            /* 
            statutList.map((statut, index) => <div className="form-check">
                 <input className="form-check-input" type="checkbox" name="flexRadioDefault" id={statut.label} />
                 <label className="form-check-label font-normal" for={statut.label} >
                     {statut.label}
                 </label>
             </div>)
             */
        }
    </div>
}

const Event = ({ event, formulaire }) => {

    const { t, i18n } = useTranslation();
    const { register } = useFormContext();
    const { formName = '', idContact } = useParams();

    const visuel = event.logo ? `http://www.mlg-consulting.com/manager_cc/events/img_uploaded/${event.logo}` :
        `${event.event_ligne?.el_visuels.large}`

    let defaultStatut = '';

    switch (formName) {

        case 'jury-onboarding': defaultStatut = 'jury';
            break;

        case 'coordinateur-techxploration-onboarding': defaultStatut = 'coordinateur-techxploration-onboarding';
            break;

        default: defaultStatut = 'offreur_de_solution';
            break;
    }

    const handleEventContactType = async ({ id_event }) => {

        let formData = new FormData();

        //formData.append('statut', defaultStatut);
        formData.append('statut', formulaire.id_event_contact_type_main);
        formData.append('id_contact', idContact);
        formData.append('id_event', id_event);
        formData.append('cf_etat', "rappeler");
        formData.append('participation_a_distance', 0);

        await fetch(`https://www.mlg-consulting.com/websites/g_event_beta/forum/views/demonstrateur/do.php?action=positionner_contact`,
            {
                method: 'POST',
                body: formData
            })
    }

    return <tr>
        <td style={{ width: "300px" }} >
            <div className="flex flex-col">
                <div className="mx-2" >
                    <div className="font-bold text-xl">{event.nom}</div> <br />
                    {visuel && <img src={visuel} alt='event logo' className="w-100" />}</div>
                <div><small className="text-muted"><i className="fas fa-map-marker-alt"></i> {event.lieu.lieu_nom} </small>
                    <br /> <small className="text-muted"><i className="fas fa-calendar-alt"></i> {event.precision_date}</small>
                </div>
            </div>
        </td>
        <td>
            <div className="roles flex flex-col">

                <label className="font-normal hidden">
                    <input
                        onClick={() => handleEventContactType({ id_event: event.id_event, id_event_contact_type: defaultStatut })}
                        data-bs-target__="#exampleModal"
                        data-bs-toggle__="modal"
                        {...register("eventSelection")}
                        className="eventSelection"
                        type="checkbox"
                        value={JSON.stringify({ id_event: event.id_event, id_event_contact_type: defaultStatut })} /> {t('form.sections.events_selection.me_prepositionner')}
                </label>

                {/* <FormulaireEventContactTypeEventComposantListHandler formulaire={formulaire} /> */}

                <FormulaireEventContactTypeListHandler event={event} />

                {/* <label className="font-normal">
                    <input className="eventSelection" type="checkbox" />	J'ai prévu de me rendre sur l'événement en simple visiteurs
                </label>
                <label className="font-normal">
                    <input className="eventSelection" type="checkbox" />	J'envisage d'exposer ou de participer aux temps forts de l'événement en groupe, merci de me recontacter à ce sujet
                </label>
                <label className="font-normal">
                    <input className="eventSelection" type="checkbox" />	Je n'ai pas prévu de me rendre sur l'événement mais peu me rendre disponible pour présenter ma solution à distance
                </label>
                <label className="font-normal">
                    <input className="eventSelection" type="checkbox" />	Pas intéressé par l'événement
                </label> */}

                <RegistrationFormContactCycleLangList event={event} />
            </div>
        </td>
    </tr>
}

const EventContactType = ({ eventContactType }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [eventContactTypeEngagementList, setEventContactTypeEngagementList] = useState(null);

    const fetchEventContactTypeEngagement = async () => {

        await fetch(`${apiUrlTranslation}?action=fetchEventContactTypeEngagement&req=WHERE id_event_contact_type = ${eventContactType.id_event_contact_type}  `)
            .then(response => response.json())
            .then(data => {
                setEventContactTypeEngagementList(data);
            })
    }

    useEffect(() => {

        fetchEventContactTypeEngagement();
    }, [i18n.resolvedLanguage])

    return <div className="form-check">
        <input className="form-check-input" type="checkbox" name="flexRadioDefault" id={eventContactType.id_event_contact_type}
            value={eventContactType.id_event_contact_type} />
        <label className="form-check-label font-normal" htmlFor={eventContactType.id_event_contact_type}>

            { /*`${eventContactType.libelle}  ${eventContactType.id_event_contact_type}` */}
            {eventContactTypeEngagementList && eventContactTypeEngagementList.map((eventContactTypeEngagement, index) =>
                <div key={eventContactTypeEngagement.id_event_contact_type_engagement}>

                    {eventContactTypeEngagement.name}

                </div>)}
        </label>
    </div>
}

const FormulaireEventContactTypeListHandler = ({ event }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [eventContactTypeList, setEventContactTypeList] = useState(null);

    const { register } = useFormContext();

    const eventContactTypeSelection = [
        { categorie: t('form.sections.events_selection.option_group_exposant'), idEventContactTypeSelection: ["238", "243"] },
        { categorie: t('form.sections.events_selection.option_group_delegation'), idEventContactTypeSelection: ["202", "237"] },
        { categorie: t('form.sections.events_selection.option_group_solo'), idEventContactTypeSelection: ["261", "135"] },
        { categorie: t('form.sections.events_selection.option_group_distance'), idEventContactTypeSelection: ["262"] },
    ]

    const getEventContactTypeList = async () => {

        const selection = eventContactTypeSelection.map(ects => ects.idEventContactTypeSelection).flat().join(',');

        await fetch(`${apiUrlTranslation}?action=getEventContactTypeList&filter=WHERE id_event_contact_type IN(${selection})`)
            .then(response => response.json())
            .then(data => {
                setEventContactTypeList(data);
            })
    }

    useEffect(() => {

        getEventContactTypeList();
    }
        , [i18n.resolvedLanguage])

    return <div>
        <input type="checkbox" /> {t('form.sections.events_selection.option_non_actif')}
        {eventContactTypeSelection.map((eventContactTypeSelectionItem, index) =>
            <div className="py-2">
                <div className="font-bold " key={index} >
                    {eventContactTypeSelectionItem.categorie}

                    {eventContactTypeList && eventContactTypeList
                        .filter(eventContactType => eventContactTypeSelectionItem.idEventContactTypeSelection.includes(eventContactType.id_event_contact_type))
                        .map((eventContactType, index) =>
                            <label className="font-normal block" key={eventContactType.id_event_contact_type}>
                                <input type="checkbox"
                                    {...register("eventSelection")}
                                    value={JSON.stringify({ id_event: event.id_event, id_event_contact_type: eventContactType.id_event_contact_type })}
                                />  {eventContactType.instructions_formulaire}
                            </label>)}
                </div>
            </div>
        )}
    </div>
}

const FormulaireEventContactTypeEventComposantListHandler = ({ formulaire }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [formulaireEventContactTypeEventComposantList, setFormulaireEventContactTypeEventComposantList] = useState(null);

    const { register } = useFormContext();

    useEffect(() => {

        const getFormulaireEventContactTypeEventComposantList = async () => {

            await fetch(`${apiUrlTranslation}?action=getFormulaireEventContactTypeEventComposantList&params=WHERE id_formulaire=${formulaire.id_formulaire}`)
                .then(response => response.json())
                .then(data => {
                    setFormulaireEventContactTypeEventComposantList(data);
                })
        }

        getFormulaireEventContactTypeEventComposantList();
    }, [i18n.resolvedLanguage])

    return (
        <div>
            {formulaireEventContactTypeEventComposantList && formulaireEventContactTypeEventComposantList
                // dédupliquer par formulaireEventContactTypeEventComposant.id_event_contact_type_event_composant.event_composant.id_event_composant
                .filter((formulaireEventContactTypeEventComposant, index, self) =>
                    index === self.findIndex((t) => (
                        t.id_event_contact_type_event_composant.event_composant.id_event_composant === formulaireEventContactTypeEventComposant.id_event_contact_type_event_composant.event_composant.id_event_composant
                    ))
                )
                .map((formulaireEventContactTypeEventComposant, index) =>
                    <div className="py-2">
                        <div className="font-bold " key={formulaireEventContactTypeEventComposant.id_formulaire_event_contact_type_event_composant} >
                            {formulaireEventContactTypeEventComposant.id_event_contact_type_event_composant.event_composant.event_composant_nom}
                        </div>

                        {
                            formulaireEventContactTypeEventComposantList && formulaireEventContactTypeEventComposantList
                                .filter(fectec => fectec.id_event_contact_type_event_composant.event_composant.id_event_composant === formulaireEventContactTypeEventComposant.id_event_contact_type_event_composant.event_composant.id_event_composant)
                                // dedupliquer par id_event_contact_type_event_composant
                                .filter((fectec, index, self) =>
                                    index === self.findIndex((t) => (
                                        t.id_event_contact_type_event_composant.event_contact_type.id_event_contact_type === fectec.id_event_contact_type_event_composant.event_contact_type.id_event_contact_type
                                    ))
                                )
                                .map((fectec, index) =>
                                    <label className="font-normal block" key={fectec.id_formulaire_event_contact_type_event_composant}>
                                        <input type="checkbox"
                                            value={fectec.id_event_contact_type_event_composant.event_contact_type.id_event_contact_type} {...register("eventSelection")} />  {fectec.id_event_contact_type_event_composant.event_contact_type.instructions_formulaire}
                                    </label>)
                        }
                    </div>
                )
            }
        </div>
    )
}


const RegistrationFormEventSection = ({ formulaire }) => {

    const [eventList, setEventList] = useState(null);

    const eventContext = useContext(EventContext);

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const { currentEvent } = eventContext;

    const { id_cycle_lang, id_event_cycle_lang, formName, idEvent } = useParams();

    const [eventContactTypeList, setEventContactTypeList] = useState(null);

    const [showBody, setShowBody] = useState(true)

    const getEventContactTypeList = async () => {

        await fetch(`${apiUrlTranslation}?action=getEventContactTypeList&filter=WHERE id_event_contact_type IN(245, 246, 209, 244, 241, 143,242,243,226)`)
            .then(response => response.json())
            .then(data => {
                setEventContactTypeList(data);

                //fetchEventContactTypeEngagement     
            })
    }

    useEffect(() => {

        // get  id_cycle_lang_preselection_list url  params

        const urlParams = new URLSearchParams(window.location.search);
        const id_cycle_lang_preselection_list = urlParams.get('id_cycle_lang_preselection_list');

        const fetchEvent = async () => {

            let cycleLangEventLigneFilter;
            let params = ` LEFT JOIN event_ligne el ON el.id_event_ligne=events.id_event_ligne WHERE (el. categorie="leading" OR el.id_event_format IN(1, 2, 5)) AND events.id_event != '${currentEvent?.id_event}' AND date(NOW())  < CONCAT(event_an,'-',event_mois,'-',event_jour) AND events.id_event NOT IN(399) AND afficher !='n' AND `;

            //const cycleLangEventLigneFilter = id_cycle_lang && formName != "startup-onboarding" ? ` events.id_event_ligne IN ( SELECT id_event_ligne FROM cycle_lang_event_ligne WHERE id_cycle_lang = ${id_cycle_lang}) AND ` : '';

            if (id_cycle_lang_preselection_list) {

                params = '';

                cycleLangEventLigneFilter = `&id_cycle_lang_preselection_list=${id_cycle_lang_preselection_list}`;

            }
            else if (!currentEvent && id_cycle_lang_preselection_list) {

                // params = ` LEFT JOIN event_ligne el ON el.id_event_ligne=events.id_event_ligne WHERE (el. categorie="leading" OR el.id_event_format IN(1, 2, 5)) AND date(NOW())  < CONCAT(event_an,'-',event_mois,'-',event_jour) AND events.id_event NOT IN(399) AND afficher !='n' AND `;

                // cycleLangEventLigneFilter = ` events.id_event_ligne IN (SELECT el.id_event_ligne FROM cycle_lang_event_ligne cel  JOIN event_ligne el ON cel.id_event_ligne = el.id_event_ligne JOIN events e ON e.id_event_ligne = el.id_event_ligne WHERE cel.id_cycle_lang IN (SELECT cel_sub.id_cycle_lang FROM cycle_lang_event_ligne cel_sub  JOIN events e_sub ON e_sub.id_event_ligne = cel_sub.id_event_ligne WHERE cel_sub.id_cycle_lang IN (${id_cycle_lang_preselection_list}))) AND
                //  `;

                params = '';

                cycleLangEventLigneFilter = `&id_cycle_lang_preselection_list=${id_cycle_lang_preselection_list}`;

            } else if (id_cycle_lang && formName != "startup-onboarding") {

                cycleLangEventLigneFilter = ` events.id_event_ligne IN ( SELECT id_event_ligne FROM cycle_lang_event_ligne WHERE id_cycle_lang = '${id_cycle_lang}') AND `

            } else if (id_event_cycle_lang) {

                cycleLangEventLigneFilter = ` events.id_event_ligne IN (SELECT el.id_event_ligne
                    FROM cycle_lang_event_ligne cel
                    JOIN event_ligne el ON cel.id_event_ligne = el.id_event_ligne
                    JOIN events e ON e.id_event_ligne = el.id_event_ligne
                    WHERE cel.id_cycle_lang IN (
                        SELECT cel_sub.id_cycle_lang
                        FROM cycle_lang_event_ligne cel_sub
                        JOIN events e_sub ON e_sub.id_event_ligne = cel_sub.id_event_ligne
                        WHERE id_cycle_lang = (SELECT id_cycle_lang FROM event_cycle_lang WHERE id_event_cycle_lang IN(${id_event_cycle_lang})
                    )
                    GROUP BY el.id_event_ligne ) AND  `;

            } else if (currentEvent) {
                cycleLangEventLigneFilter = ` events.id_event_ligne IN (SELECT el.id_event_ligne
                    FROM cycle_lang_event_ligne cel
                    JOIN event_ligne el ON cel.id_event_ligne = el.id_event_ligne
                    JOIN events e ON e.id_event_ligne = el.id_event_ligne
                    WHERE cel.id_cycle_lang IN (
                        SELECT cel_sub.id_cycle_lang FROM cycle_lang_event_ligne cel_sub
                        JOIN events e_sub ON e_sub.id_event_ligne = cel_sub.id_event_ligne
                        WHERE e_sub.id_event = '${idEvent}'
                    )
                    GROUP BY el.id_event_ligne ) AND `;
            } else {


                cycleLangEventLigneFilter = "";
                params = ` LEFT JOIN event_ligne el ON el.id_event_ligne=events.id_event_ligne WHERE (el. categorie="leading" OR el.id_event_format IN(1)) AND date(NOW())  < CONCAT(event_an,'-',event_mois,'-',event_jour) AND events.id_event NOT IN(399) AND afficher !='n' AND    `;
            }

            //SELECT el.id_event_ligne FROM cycle_lang_event_ligne cel, event_ligne el, events e WHERE cel.id_event_ligne=el.id_event_ligne && e.id_event_ligne = el.id_event_ligne && e.id_event=1768;

            //const cycleLangEventLigneFilter = id_cycle_lang ? ` events.id_event_ligne IN ( SELECT id_event_ligne FROM cycle_lang_event_ligne WHERE id_cycle_lang = ${id_cycle_lang}) AND ` : '';

            //cycleLangEventLigneFilter = "";

            // await axios(`${apiUrlTranslation}?action=getEvents&params=${params}${cycleLangEventLigneFilter}`)
            //     .then(res => setEventList(res.data))


            await fetch(`${apiUrlTranslation}?action=getEvents&params=${params}${cycleLangEventLigneFilter}`)
                .then(res => res.json())
                .then(res => setEventList(res))

        }

        fetchEvent();
        getEventContactTypeList()

    }, [currentEvent, i18n.resolvedLanguage]);

    if (!eventContactTypeList) {
        return false
    }
    return <RegistrationFormSection
        formSectionId={25}
        title={formulaire.event_section_title ?? t('form.sections.events_selection.section_title')}
        instructions={formulaire.event_section_instructions ?? t('form.sections.events_selection.section_instructions')}>

        {/* 
        <label className="font-normal block">
            <input
                name='events_reveal'
                type="radio"
                onChange={() => setShowBody(!showBody)}
                checked={showBody} /> {t('form.sections.events_selection.body_reveal_input')}
        </label>

        <label className="font-normal block">
            <input
                name='events_reveal'
                type="radio"
                onChange={() => setShowBody(!showBody)}
            /> Je ne prévois pas de me rendre sur des événements de mon secteur
        </label> */}

        {showBody &&
            <>

                { }
                <table className='table'>
                    <tbody>
                        {eventList && eventList.map(event => <Event
                            key={event.id_event}
                            event={event}
                            formulaire={formulaire}
                        />
                        )}
                    </tbody>
                </table>

                <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog  modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">Mode de participation</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">

                                <DisplayStatutList
                                    title="Je ne serai pas présent mais précise si je souhaite positionner l'événement dans l'agenda de mon collectif ou m'y connecter à distance "
                                    statutList={statutList_1}
                                    eventContactTypeList={eventContactTypeList.filter(eventContactType => ["245", "246", "209"].includes(eventContactType.id_event_contact_type))} />

                                <DisplayStatutList
                                    title="Je m'y rends et précise mes attentes vis-à-vis de myGlobalVillage "
                                    statutList={statutList_2}
                                    eventContactTypeList={eventContactTypeList.filter(eventContactType => ["143", "244", "241"].includes(eventContactType.id_event_contact_type))}
                                />

                                <DisplayStatutList
                                    title="J'ai un stand et précise mes attentes ou proposition d'intervention de myGlobalVillage "
                                    statutList={statutList_3}
                                    eventContactTypeList={eventContactTypeList.filter(eventContactType => ["243", "243"].includes(eventContactType.id_event_contact_type))} />

                                <DisplayStatutList
                                    title="Vous êtes l'organisateur de l'événement "
                                    statutList={statutList_4}
                                    eventContactTypeList={eventContactTypeList.filter(eventContactType => ["226"].includes(eventContactType.id_event_contact_type))}
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="hover:bg-blue-400 bg-blue-500" data-bs-dismiss="modal">Fermer</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        }
    </RegistrationFormSection>
}

export default RegistrationFormEventSection;