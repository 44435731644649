import RegistrationFormSection from './RegistrationFormSection'

// const RegistrationFormCycleLangListSection = () => {
//     return (
//         <RegistrationFormSection
//             title="RegistrationFormCycleLangList">

//         </RegistrationFormSection>
//     )
// }

// export default RegistrationFormCycleLangListSection


import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import CollectifAnnuaireItem from "./CollectifAnnuaireItem";
import Layout from "./Layout";
import Loader from "./Loader";
import useApiUrlTranslation from "./useApiUrlTranslation";
import RegistrationFormCycleLangListItemSection from './RegistrationFormCycleLangListItemSection';
import HTMLReactParser from 'html-react-parser';
import { RegistrationFormSupertitleSection } from './RegistrationFormStyleSection';

const RegistrationFormCycleLangListSection = ({ cycleLangType, formulaire, contact }) => {


    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation()

    const [contactCycleLangList, setContactCycleLangList] = useState(null);

    const [cycle_lang_list, setCycleLangList] = useState(null);

    //const page_title = `${cycle_lang_type.split("_")[0]}s ${cycle_lang_type.split("_")[1]}s`;

    let page_title;
    let pageSubtitle;

    switch (cycleLangType) {

        case 'collectif_geographique':
            page_title = t("nav.collectifs_geographiques");
            pageSubtitle = `Bienvenue dans l'annuaire des hubs continentaux de myGlobalVillage.
      Vous souhaitez rejoindre la galaxie myglobalvillage et bénéficier d'une offre d'essaie Freemium de 3 mois. Sélectionnez-le ou les pays dans la liste ci-dessous sur lesquels vous êtes actifs et rejoignez les cercles d'intelligence collective de votre choix.`;

            break;
        case 'collectif_sectoriel':
            page_title = t("nav.collectifs_sectoriels");
            break;

        case 'collectif_technologique':
            page_title = t("nav.collectifs_technologiques");
            break;

        case 'super_collectif':
            page_title = t("nav.collectifs_super");
            break;

        default: page_title = cycleLangType
    }

    const fetchContactCycleLang = async ({ id_contact }) => {

        await fetch(`${apiUrlTranslation}?action=fetchContactCycleLang&filter= c.id_contact = "${id_contact}" AND `)
            .then(res => res.json())
            .then(res => setContactCycleLangList(res))
    }

    useEffect(() => {

        const fetchCycleLang = async () => {

            let req_suite;

            if (cycleLangType === "all") {
                req_suite = ` IN('collectif_geographique', 'collectif_technologique','collectif_sectoriel','super_collectif','collectif_partenaire') `;
            } else if (cycleLangType == "collectif_geographique") {
                req_suite = ` = 'collectif_geographique' AND cycle_lang_categorie IN('continent')`;

            } else {
                req_suite = ` = '${cycleLangType}'`;
            }

            const url = `${apiUrlTranslation}?action=getCycleLang&filter=cycle_lang_type ${req_suite} AND cycle_lang_state NOT IN('hidden') AND  `;

            await fetch(url)
                .then((res) => res.json())
                .then((res) => {
                    setCycleLangList(
                        res.filter(
                            (cycle_lang) => cycle_lang.cycle_lang_type === cycleLangType
                        )
                    )
                })
        }

        contact && fetchContactCycleLang({ id_contact: contact.id_contact });

        fetchCycleLang();

    }, [
        page_title,
        cycleLangType,
        i18n.resolvedLanguage,
        contact
    ])

    return (
        <RegistrationFormSection

            formSectionId={18}
            supertitle_={<RegistrationFormSupertitleSection>

                Recherche de distributeurs ou de partenaires internationaux : demande de mise en connexion avec le cercle des Tech'Xporter

            </RegistrationFormSupertitleSection>}

            title={formulaire.collectif_geographique_section_title}
            instructions={HTMLReactParser(formulaire.collectif_geographique_section_instructions)}>

            <p className="text-4xl py-3 hidden">Actifs</p>
            <div className=" divide-y"> {cycle_lang_list ? (
                cycle_lang_list
                    .filter(cycle_lang => cycle_lang.cycle_lang_state == 'active')
                    .map((cycle_lang) => (
                        <RegistrationFormCycleLangListItemSection
                            contactCycleLangList={contactCycleLangList}
                            cycle_lang={cycle_lang}
                            formulaire={formulaire} />
                    ))
            ) : (
                <Loader />
            )}

            </div>
        </RegistrationFormSection>
    )
}

//CollectifAnnuaireItem
//CollectifAnnuaireItemContact

export default withRouter(RegistrationFormCycleLangListSection);