import { useEffect, useState } from "react";
import useApiUrlTranslation from "./useApiUrlTranslation";
import { useFormContext } from "react-hook-form";

const CycleLangSelection = ({ idCycleLangList, formulaire, cycleLangSelection }) => {

    const { register } = useFormContext();

    const [cycleLangList, setCycleLangList] = useState(false);

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const fetchCycleLang = async ({ idCycleLangList }) => {

        const filter = ` id_cycle_lang IN(${idCycleLangList}) AND `

        await fetch(`${apiUrlTranslation}?action=getCycleLang&filter=${filter}`)
            .then(res => res.json())

            .then(res => {
                setCycleLangList(res)
            })
    }

    useEffect(() => {
        fetchCycleLang({ idCycleLangList });

    }, [i18n.resolvedLanguage, cycleLangSelection])

    return (

        <>
            {/* <p className=' text-lg font-bold'>Je détermine la contribution opérationnelle de mon écosystème</p>

            <p className='mb-2'>Merci de générer dans mon espace coordinateur les liens pour recruter les profils ci-dessous au sein de mon écosystème.</p> */}

            {
                cycleLangList && cycleLangList.map(cycleLang => {

                    const selection = cycleLangSelection.find(cycleLangSelection => cycleLangSelection.id_cycle_lang == cycleLang.id_cycle_lang);

                    return <label label className="block font-normal" >


                        <input
                            {...register("cycleLangIdEventContactTypeList")}
                            value={JSON.stringify({ id_cycle_lang: cycleLang.id_cycle_lang, id_event_contact_type: formulaire.id_event_contact_type_main })}
                            type="checkbox" name="" id="" /> <span className="font-bold">{cycleLang.nom_cycle_lang} </span>:  <span className="text-gray-600">{cycleLang.cycle_lang_descriptif}</span></label >
                })
            }
        </>
    )
}


export default CycleLangSelection;